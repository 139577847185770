<template>
  <div class="c_box--gray_235">
    <div class="c_promo_list c_wrapper c_grid c_grid--2">
      <Promo v-for="(promo, index) in data" :key="index" :promo="promo" />
    </div>
  </div>
</template>

<script>
import Promo from './Promo.vue';
export default {
  components: {
    Promo,
  },
  data() {
    return {};
  },
  props: {
    data: Array,
  },
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="scss">
.c_promo_list {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  &.c_grid {
    display: block;
  }
  @include mq($mq_md_to_lg) {
    padding-top: 4rem;
    padding-bottom: 1.5rem;
    &.c_grid {
      display: grid;
      grid-gap: 0 1.5rem;
    }
  }
}
</style>
