<template>
  <div class="c_related_customer_story__container c_box c_flex" ref="c_related_customer_story">
    <div class="c_related_customer_story">
      <span class="c_related_customer_story__location c_2022--body">
        {{ location }}
      </span>
      <h2 class="c_2022--h2_schnyder c_related_customer_story__title">
        {{ title }}
      </h2>
      <div class="c_related_customer_story__description c_2022--lead" v-html="description"></div>
      <div class="c_related_customer_story__secondary" v-if="secondaryTitle || secondaryDescription">
        <span class="c_2022--body c_related_customer_story__secondary__title" v-if="secondaryTitle">
          {{ secondaryTitle }}
        </span>
        <div
          class="c_2022--body c_related_customer_story__secondary__description"
          v-html="secondaryDescription"
          v-if="secondaryDescription"
        ></div>
      </div>
      <Button :href="getHref()" class="c_button_2022 c_button_2022__secondary">
        {{ $t('Read The Story') }}
      </Button>
    </div>
  </div>
</template>

<script>
import Button from 'Components/button/Button.vue';
import { openPdfsInNewTab } from 'JS/global.js';
export default {
  props: {
    location: String,
    title: String,
    description: String,
    link: String,
    secondaryTitle: String,
    secondaryDescription: String,
  },
  components: {
    Button,
  },
  methods: {
    getHref() {
      return this.localePath(`/${this.link}`);
    },
  },
  mounted() {
    openPdfsInNewTab(this.$refs.c_related_customer_story);
  },
};
</script>

<style lang="scss">
.c_related_customer_story__container {
  padding-left: 0;
  padding-right: 0;
  @include mq($mq_sm_to_md) {
    padding-left: px_to_rem(10);
    padding-right: px_to_rem(10);
  }
}
.c_related_customer_story {
  $self: &;
  background-color: $color_black;
  color: $color_white;
  border-radius: px_to_rem(12);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: px_to_rem(32) px_to_rem(15) px_to_rem(39) px_to_rem(15);
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(30) px_to_rem(25);
  }
  @at-root #{&}__location {
    @include font('graphik_medium');
    color: $color_yellow;
    margin-bottom: px_to_rem(25);
  }
  @at-root #{&}__title {
    @include font('schnyder_wide_m_bold');
    margin-bottom: px_to_rem(30);
  }
  @at-root #{&}__description {
    margin-bottom: 70px;
    a:visited,
    a {
      color: $color_white;
    }
  }
  @at-root #{&}__secondary {
    margin-bottom: px_to-rem(35);
    a:visited,
    a {
      color: $color_white;
    }
    @at-root #{&}__title {
      display: block;
      color: $color_yellow;
    }
  }
  .c_button_2022 {
    margin-top: auto;
    align-self: flex-start;
  }
}
</style>
