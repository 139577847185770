<template>
  <div class="c_quote_slider__content__wrapper">
    <div class="c_quote_slider__content">
      <div class="c_quote_slider__content__text">
        <h2 class="c_2022--h2_schnyder" v-if="slide.quoteTitle">{{ slide.quoteTitle }}</h2>
        <blockquote
          v-if="slide.quote"
          class="c_quote_slider__content__text__quote c_2022--blockquote_schnyder"
          v-html="'&quot;' + slide.quote + '&quot;'"
        ></blockquote>
        <div class="c_quote_slider__attribution">
          <MediaImage
            v-if="slide.authorImage.length"
            :sources="[{ src: slide.authorImage[0].aspectRatio11.srcWebp }]"
          />
          <div class="c_quote_slider__attribution__text">
            <cite
              aria-label="Quote author"
              v-if="slide.quoteAuthor"
              class="c_quote_slider__content--name c_text--graphik_regular c_2022--body"
              v-html="slide.quoteAuthor"
            ></cite
            ><span v-if="slide.descriptor">, </span
            ><cite
              aria-label="Quote author descriptor"
              v-if="slide.descriptor"
              v-html="slide.descriptor"
              class="c_quote_slider__content--descriptor c_text--graphik_regular c_2022--body"
            ></cite
            ><span v-if="slide.workplace">, </span
            ><cite
              aria-label="Quote author workplace"
              v-if="slide.workplace"
              v-html="slide.workplace"
              class="c_quote_slider__content--workplace c_text--graphik_regular c_2022--body"
            ></cite>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MediaImage from 'Components/image/MediaImage.vue';
export default {
  components: {
    MediaImage,
  },
  props: {
    slide: Object,
  },
};
</script>

<style lang="scss">
.c_quote_slider_2022 {
  .c_quote_slider {
    $self: &;
    @at-root #{$self}__content {
      $content: &;
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      @include mq($mq_sm_to_md) {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
      }
      @at-root #{&}--background {
        color: $color_white;
      }
      @at-root #{&}__wrapper {
        position: relative;
        z-index: 50;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: 0;
        @include mq($mq_sm_to_md) {
          padding: 0 px_to_rem(40);
        }
        @include mq($mq_sm_to_md) {
          justify-content: center;
        }
      }

      @at-root #{&}__text {
        flex: 0 1 auto;
        order: 2;
        width: 100%;
        @include mq($mq_sm_to_md) {
          order: 1;
        }
        @at-root #{&}__quote {
          @include font('schnyder_wide_m_light');
          font-weight: bold;
          margin-bottom: px_to_rem(20);
          @include mq($mq_md_to_lg) {
            margin-bottom: px_to_rem(60);
          }
        }
        h2 {
          @include font('schnyder_wide_m_bold');
          margin-bottom: px_to_rem(30);
          @include mq($mq_sm_to_md) {
            margin-bottom: px_to_rem(40);
          }
        }
      }
      @at-root #{&}--name {
        @include font('graphik_semibold');
      }
      @at-root #{&}--descriptor {
        @include font('graphik_regular_italic');
      }
      @at-root #{&}--workplace {
        @include font('graphik_medium_italic');
      }
    }
    @at-root #{$self}__attribution {
      display: flex;
      align-items: center;
      justify-content: center;
      & > span {
        flex-shrink: 0;
      }
      img {
        width: px_to_rem(50);
        height: px_to_rem(50);
        border-radius: 50%;
        overflow: hidden;
        margin-right: px_to_rem(20);
      }
      figure {
        align-self: center;
        flex-shrink: 0;
      }
    }
  }
}
</style>
