<template>
  <section class="c_promo" :class="cardStyle" :style="hasBackgroundImage(promo.promoImage?.[0]?.lpImageTextUncropped)">
    <span class="c_promo__tab" :class="tabStyle">
      {{ getTabLabel() }}
    </span>
    <div class="c_promo__content">
      <div class="c_promo__eyebrow">
        {{ getEyebrow() }}
      </div>
      <h2 class="c_promo__title">{{ promo.promoTitle }}</h2>
      <MediaImage
        :class="`c_promo__image`"
        :sources="[promo.promoImage[0]?.lpImageTextUncropped]"
        :alt="promo.promoTitle"
        v-if="
          promo.promoImage[0]?.lpImageTextUncropped &&
          promo.promoImage[0]?.lpImageTextUncropped !== undefined &&
          (promo.promoCardStyle == 'yellow' || promo.promoCardStyle == 'white')
        "
      />
      <div class="c_promo__description" v-html="promo.promoDescription"></div>
      <div class="c_promo__buttons">
        <Button
          v-for="(button, index) in promo.linksMatrix2"
          :key="index"
          class="c_button_2022"
          :class="getButtonClass(index)"
          :href="getHrefs(button)"
          :target="newWindow(button)"
          :aria-label="button.ariaLabel"
        >
          {{ button.buttonLabel }}
        </Button>
      </div>
    </div>
  </section>
</template>

<script>
import { log } from 'JS/global.js';
import MediaImage from 'Components/image/MediaImage.vue';
import Button from 'Components/button/Button.vue';
export default {
  components: {
    Button,
    MediaImage,
  },
  data() {
    return {};
  },
  props: {
    promo: Object,
  },
  methods: {
    getTabLabel() {
      if (this.promo.typeHandle === 'eventV2' && this.eventType) {
        return this.eventType;
      } else if (this.promo.typeHandle === 'eventV2') {
        return 'Event';
      } else if (this.promo.typeHandle === 'interactiveWebGLDemo') {
        return 'Interactive Demo';
      } else if (this.promo.typeHandle === 'whitePaper') {
        return 'White Paper';
      } else return '';
    },
    getEyebrow() {
      if (this.promo.typeHandle === 'eventV2' && this.eventType === 'Webinar' && this.promo.eventRecordingUrl) {
        return 'On Demand';
      } else if (this.promo.typeHandle === 'eventV2') {
        return this.promo.eventStartDateTime;
      } else {
        return this.promo.promoEyebrow || '';
      }
    },
    getButtonClass(index) {
      return index === 0 ? 'c_button_2022__primary' : 'c_button_2022--text_black c_button_2022__secondary';
    },
    getHrefs(btn) {
      if (btn.entry) {
        return this.localePath(`/${btn.entry[0]?.uri}`);
      } else if (btn.asset) {
        return this.localePath(`/${btn.asset[0]?.uri}`);
      } else if (btn.productCategory) {
        return this.localePath(`/${btn.productCategory[0]?.uri}`);
      } else return btn.urlText || null;
    },
    newWindow(btn) {
      return btn.openInANewWindow ? '_blank' : null;
    },
    // hasBackgroundImage(img) {
    //   const portraitImgs = img?.optimizedImageUrls[2] || '';
    //   if (this.promo.promoCardStyle === 'image' && portraitImgs !== undefined) {
    //     return 'background-image: url(' + portraitImgs + ')';
    //   }
    // },
    hasBackgroundImage(img) {
      const portraitImgs = img?.optimizedImageUrls[2] || '';
      if (this.promo.promoCardStyle === 'image' && portraitImgs !== undefined) {
        return 'background-image: url(' + portraitImgs + ')';
      }
      return '';
    },
  },
  created() {
    log('Promo Block');
  },
  computed: {
    eventType() {
      for (const filter in this.promo.eventFilters) {
        if (this.promo.eventFilters[filter].parent !== undefined && this.promo.eventFilters[filter].parent !== null) {
          if (this.promo.eventFilters[filter].parent.slug === 'type') {
            return this.promo.eventFilters[filter].title;
          }
        }
      }
      return false;
    },
    hasButtons() {
      return this.promo.linksMatrix2.length > 0;
    },
    cardStyle() {
      switch (this.promo.promoCardStyle) {
        case 'yellow':
          return 'c_promo__yellow';
        case 'image':
          return 'c_promo__background_image';
        default:
          return 'c_promo__white';
      }
    },
    tabStyle() {
      switch (this.promo.promoTabStyle) {
        case 'yellow':
          return 'c_promo__tab--yellow';
        default:
          return 'c_promo__tab--black';
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.c_promo {
  padding: 1rem;
  padding-top: 0;
  border-bottom-left-radius: 0.9375rem;
  border-bottom-right-radius: 0.9375rem;
  background-color: $color_white;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  margin-bottom: 2rem;
  &.c_promo__yellow {
    background-color: $color_yellow;
  }
  &.c_promo__background_image {
    display: block;
    background-size: cover;
    background-position: center;
    .c_promo__content {
      background-color: $color_white;
      padding: 1rem;
      margin-bottom: 9.375rem;
      @include mq($mq_sm_to_md) {
        margin-bottom: 15.625rem;
      }
    }
  }
  .c_promo__tab {
    display: inline-block;
    padding: 0.8125rem 0.9375rem;
    margin-bottom: 2rem;
    @include font('graphik_semibold');
    font-size: 1rem;
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    &.c_promo__tab--black {
      background-color: $color_black;
      color: $color_white;
    }
    &.c_promo__tab--yellow {
      background-color: $color_yellow;
    }
  }
  .c_promo__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    font-size: 1rem;
    .c_promo__eyebrow {
      @include font('graphik_bold');
      margin-bottom: 1rem;
    }
    .c_promo__title {
      font-size: 2rem;
      @include font('graphik_medium');
      margin-bottom: 1rem;
    }
    .c_promo__image {
      margin-bottom: 1rem;
    }
    .c_promo__description {
      p,
      ul,
      ol {
        margin-bottom: 1rem;
        strong,
        b {
          @include font('graphik_bold');
        }
        em,
        i {
          font-style: italic;
        }
      }
      ul {
        list-style: disc;
      }
      ol {
        list-style: normal;
      }
      ul,
      ol {
        li {
          margin-left: 1rem;
        }
      }
    }
    .c_promo__buttons {
      margin-top: auto;
      padding-top: 1rem;
      .c_button_2022 {
        margin-right: 1.25rem;
        margin-bottom: 1.25rem;
      }
    }
  }
  @include mq($mq_sm_to_md) {
    padding: 2rem;
    padding-top: 0;
    &.c_promo__background_image {
      .c_promo__content {
        padding: 2rem;
        font-size: 1.125rem;
      }
    }
    .c_promo__tab {
      padding: 1.125rem 2rem;
      font-size: 1.125rem;
      margin-bottom: 3.4375rem;
    }
  }
}
</style>
