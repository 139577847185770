var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"c_promo_block",class:{ 'c_promo_block--no_padding': !_vm.backgroundImage, 'c_promo_block--no_title': !_vm.displayTitle },style:([
    _vm.backgroundImage && _vm.image?.length
      ? {
          backgroundImage: 'url(' + _vm.image?.[0].uncropped1920w.src + ')',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }
      : '',
  ])},[_c('div',[_c('div',{class:_vm.backgroundImage ? 'c_wrapper' : 'c_wrapper--image_text'},[(!_vm.backgroundImage)?_c('div',{staticClass:"c_promo_block__image_container"},[_c('MediaImage',{attrs:{"sources":_vm.mediaSources}})],1):_vm._e(),_vm._v(" "),_c('RelatedCustomerStory',{class:{ 'c_related_customer_story__container--white': !_vm.backgroundImage },attrs:{"location":_vm.location,"title":_vm.primaryTitle,"description":_vm.primaryDescription,"link":_vm.uri,"secondary-title":_vm.secondaryTitle,"secondary-description":_vm.secondaryDescription}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }