<template>
  <section class="c_promo_blocks">
    <div class="c_promo_blocks__title" v-if="displayTitle">
      <h2 class="c_2022--body-sm">{{ $t('Customer Story') }}</h2>
    </div>
    <PromoBlockSlide
      v-if="!isSlider"
      :primary-title="slides[0]?.cardTitle"
      :primary-description="slides[0]?.cardDescription"
      :secondary-title="slides[0]?.customerStorySecondaryTitle"
      :secondary-description="slides[0]?.customerStorySecondaryDescription"
      :location="slides[0]?.location"
      :uri="slides[0]?.uri"
      :image="slides[0]?.customerStoryImage"
      :block-styling="blockStyling"
    />
    <client-only v-if="isSlider">
      <div class="c_agile_slider_2022">
        <agile @after-change="getCurrentSlide($event)" :options="agileOptions">
          <PromoBlockSlide
            v-for="(slide, index) in slides"
            :key="index"
            :primary-title="slide?.cardTitle"
            :primary-description="slide?.cardDescription"
            :secondary-title="slide?.customerStorySecondaryTitle"
            :secondary-description="slide?.customerStorySecondaryDescription"
            :location="slide?.location"
            :uri="slide?.uri"
            :image="slide?.customerStoryImage"
            :block-styling="blockStyling"
            :display-title="displayTitle"
          />
        </agile>
        <mq-layout mq="sm">
          <SlideNumbers :number-of-slides="getNumberOfSlides()" :current-slide="currentSlide" />
        </mq-layout>
      </div>
    </client-only>
  </section>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from '@nuxtjs/composition-api';
import { VueAgile } from 'vue-agile';
import SlideNumbers from 'Components/slider/SlideNumbers.vue';
import PromoBlockSlide from './PromoBlockSlide.vue';

export default defineComponent({
  name: 'PromoBlock',
  components: {
    PromoBlockSlide,
    agile: VueAgile,
    SlideNumbers,
  },
  props: {
    slides: Array,
    blockStyling: String,
    displayTitle: Boolean,
  },
  setup(props) {
    const state = reactive({
      isSlider: false,
      currentSlide: null,
      agileOptions: {
        infinite: true,
        slidesToShow: 1,
        navButtons: true,
        dots: false,
        fade: true,
      },
    });

    state.isSlider = props.slides.length > 1;

    const getCurrentSlide = (e) => {
      return (state.currentSlide = e.currentSlide + 1);
    };

    const getNumberOfSlides = () => {
      return props.slides.length;
    };

    // Change slider animation style for mobile.
    onMounted(() => {
      state.agileOptions.fade = window?.innerWidth > 850;
    });

    return { ...toRefs(state), getCurrentSlide, getNumberOfSlides };
  },
});
</script>

<style lang="scss">
.c_promo_blocks {
  $self: &;
  position: relative;
  @at-root #{&}__title {
    display: flex;
    justify-content: center;
    position: static;
    margin-bottom: px_to_rem(40);
    @include mq($mq_sm_to_md) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 100;
    }
    h2 {
      padding: px_to_rem(20) px_to_rem(50);
      background-color: $color_yellow;
      @include font('graphik_medium');
    }
  }
  .agile__nav-button {
    @at-root #{&}--prev {
      @include mq(450, '', 'rem') {
        left: px_to_rem(0) !important;
      }
      @include mq($mq_sm_to_md, '', 'rem') {
        left: px_to_rem(50) !important;
      }
      @include mq($mq_md_to_lg, '', 'rem') {
        left: px_to_rem(50) !important;
      }
      @include mq(1730, '', 'rem') {
        left: calc(150px - var(--slider-arrow-distance-offset-sm, 0px)) !important;
      }
    }
    @at-root #{&}--next {
      @include mq(450, '', 'rem') {
        right: px_to_rem(0) !important;
      }
      @include mq($mq_sm_to_md, '', 'rem') {
        right: px_to_rem(50) !important;
      }
      @include mq($mq_md_to_lg, '', 'rem') {
        right: px_to_rem(50) !important;
      }
      @include mq(1730, '', 'rem') {
        right: calc(150px - var(--slider-arrow-distance-offset-sm, 0px)) !important;
      }
    }
  }
  .agile__actions {
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
    padding-right: var(--wrapper-padding-right, var(--wrapper-padding, 5vw));
    max-width: $max_width;
    box-sizing: var(--wrapper-box-sizing, content-box);
  }
}
</style>
