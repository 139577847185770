<template>
  <div
    class="c_promo_block"
    :class="{ 'c_promo_block--no_padding': !backgroundImage, 'c_promo_block--no_title': !displayTitle }"
    :style="[
      backgroundImage && image?.length
        ? {
            backgroundImage: 'url(' + image?.[0].uncropped1920w.src + ')',
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }
        : '',
    ]"
  >
    <div>
      <div :class="backgroundImage ? 'c_wrapper' : 'c_wrapper--image_text'">
        <div v-if="!backgroundImage" class="c_promo_block__image_container">
          <MediaImage :sources="mediaSources" />
        </div>
        <RelatedCustomerStory
          :class="{ 'c_related_customer_story__container--white': !backgroundImage }"
          :location="location"
          :title="primaryTitle"
          :description="primaryDescription"
          :link="uri"
          :secondary-title="secondaryTitle"
          :secondary-description="secondaryDescription"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRefs, reactive } from '@nuxtjs/composition-api';
import RelatedCustomerStory from 'Components/2023_related_customer_stories/RelatedCustomerStory.vue';
import MediaImage from 'Components/image/MediaImage.vue';

export default defineComponent({
  name: 'PromoBlockSlide',
  components: { RelatedCustomerStory, MediaImage },
  props: {
    primaryTitle: String,
    primaryDescription: String,
    secondaryTitle: String,
    secondaryDescription: String,
    location: String,
    uri: String,
    image: Array,
    blockStyling: String,
    displayTitle: Boolean,
  },
  setup(props) {
    const state = reactive({
      backgroundImage: false,
      roundedCorners: false,
      mediaSources: [],
    });

    state.backgroundImage = props.blockStyling === 'backgroundImage';
    state.mediaSources.push(props.image?.[0]?.uncropped1920w ?? []);

    return { ...toRefs(state) };
  },
});
</script>

<style lang="scss">
.c_promo_block {
  $self: &;

  display: flex;
  align-items: center;

  // Fixes a bug with vue-agile where slide is sometimes not full-width.
  & > div {
    width: 100%;
  }
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(100) 0;
    min-height: px_to_rem(900);
  }
  @at-root #{&}--no_padding {
    padding-left: var(--wrapper-padding-left, var(--wrapper-padding, 5vw));
    padding-right: var(--wrapper-padding-right, var(--wrapper-padding, 5vw));
    & > div {
      height: 100%;
    }
    @include mq($mq_sm_to_md) {
      padding: 0;
      min-height: 0;
    }
  }
  @at-root #{&}--no_title {
    margin-top: px_to_rem(40);
    @include mq($mq_sm_to_md) {
      margin-top: 0;
    }
  }
  .c_related_customer_story {
    max-width: 100%;
    border-radius: px_to_rem(12);
    margin-left: 0;
    @include mq($mq_sm_to_md) {
      max-width: px_to_rem(400);
      border-radius: 0;
    }
    @include mq($mq_md_to_lg) {
      max-width: px_to_rem(450);
    }
    @media (min-width: 1024px) and (max-width: 1400px) {
      margin-left: px_to_rem(100);
    }
  }
  .c_wrapper {
    @at-root #{&}--image_text {
      height: 100%;
      .c_promo_block {
        @at-root #{&}__image_container {
          display: none;
        }
      }
      @include mq($mq_sm_to_md) {
        display: flex;
        .c_promo_block {
          @at-root #{&}__image_container {
            display: block;
            width: 50%;
            flex: 0 0 50%;
            figure {
              display: block;
              height: 100%;
              picture {
                display: flex;
                height: 100%;
                img {
                  object-fit: cover;
                }
              }
            }
          }
          .c_related_customer_story {
            @at-root #{&}__container {
              width: 50%;
              flex: 0 0 50%;
            }
          }
        }
      }
    }
    @media (min-width: 700px) and (max-width: 1024px) {
      display: flex;
      justify-content: center;
    }
  }
  .c_related_customer_story__container {
    padding: 0;

    // Styling for image | text layout
    @at-root #{&}--white {
      .c_related_customer_story {
        min-height: 0;
        @include mq($mq_sm_to_md) {
          background-color: $color_white;
          color: $color_black;
          max-width: 100%;
          min-height: 35vw;
          padding: px_to_rem(100) px_to_rem(100) px_to_rem(80) px_to_rem(40);
          margin-left: 0;
          .c_button_2022 {
            color: $color_black;
            margin-top: 0;
            &:hover {
              color: $color_white;
            }
          }
          @at-root #{&}__description {
            max-width: px_to_rem(475);
          }
          @at-root #{&}__secondary {
            max-width: px_to_rem(475);
          }
          @at-root #{&}__location {
            color: $color_black;
          }
          @at-root #{&}__secondary__title {
            color: $color_black;
            @include font('graphik_semibold');
          }
        }
        @include mq($mq_md_to_lg) {
          padding: px_to_rem(100) px_to_rem(140) px_to_rem(80) px_to_rem(80);
        }
      }
    }
  }
  @media (max-width: 699px) {
    // Important required to override inline style
    background-image: none !important;

    // Makes all children the same height
    & > div,
    .c_wrapper,
    .c_wrapper > div,
    .c_wrapper--image_text,
    .c_wrapper--image_text > div {
      height: 100%;
    }
  }
}
</style>
